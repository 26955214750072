<template lang="pug">
.background
.full-screen
  .container
    .wrapper-card
      .card
        .header
          Logo
        .loader(:class="classLoading")
        .content
          router-view 
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  data: () => ({}),
  setup() {
    const route = useRoute();
    const classes = {
      Quiz: "step-1",
      Quiz_2: "step-2",
      Quiz_3: "step-3",
      Quiz_4: "step-4",
      Quiz_measures: "step-5",
      Quiz_loading: "step-5 loading",
      Quiz_email: "step-5 loading",
    };
    const classLoading = computed(() => {
      return classes[route.name];
    });

    return {
      classLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  min-height: 100vh;
  width: 100%;
  //backdrop-filter: blur(5px);
}

.wrapper-card {
  @apply flex justify-center py-36;
}

.card {
  width: 100%;
  max-width: 520px;
  background-color: rgba(255, 255, 255, 0.8);
  @apply rounded-xl border-2 border-green-500;
  box-shadow: 0 10px 15px -5px rgba(132, 204, 22, 0.2),
    0 5px 10px -5px rgba(132, 204, 22, 0.1);

  .header,
  .content {
    @apply p-4;
  }

  .loader {
    height: 4px;
    width: 100%;
    @apply bg-green-100 relative;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      @apply bg-green-500;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      transition: width 1s ease-in-out;
      width: 1%;
    }

    &.step-1 {
      &::before {
        width: 20%;
      }
    }
    &.step-2 {
      &::before {
        width: 40%;
      }
    }
    &.step-3 {
      &::before {
        width: 60%;
      }
    }
    &.step-4 {
      &::before {
        width: 80%;
      }
    }
    &.step-5 {
      &::before {
        width: 100%;
      }
    }
  }
}

.blob {
  background-image: url(~@/assets/blob_1.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 600px;
  position: absolute;
  z-index: -1;
  left: -250px;
  bottom: 0px;
  opacity: 0.5;
  transform: rotate(0deg);
  &::before {
    content: "";
    background-image: url(~@/assets/avocado.svg);
    background-size: cover;
    width: 60%;
    height: 60%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto auto;
    top: 0;
    bottom: 0;
  }
}
</style>